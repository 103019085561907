import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import Maplestory from '../views/Maplestory.vue';
import About from '../views/About.vue';
import Contact from '../views/Contact.vue';
import VioletCube from '@/components/VioletCube.vue'
import UniCube from '@/components/UniCube.vue'
import EqualityCube from '@/components/EqualityCube.vue'
import PowerMeterCompare from '@/components/powerMeterCompare/PowerMeterCompare.vue'
import MaplestoryAbout from '@/components/MaplestoryAbout.vue'
import WheelsetCompare from '../views/WheelsetCompare.vue';

const routes = [
  { path: '/', component: Home },
  { path: '/maplestory',component: Maplestory,},
  { path: '/maplestory/violetCube', component: VioletCube },
  { path: '/maplestory/uniCube', component: UniCube },
  { path: '/maplestory/equalityCube', component: EqualityCube },
  { path: '/maplestory/about', component: MaplestoryAbout},
  { path: '/powerMeterCompare', component: PowerMeterCompare },
  { path: '/about', component: About },
  { path: '/contact', component: Contact },
  { path: '/wheelsetCompare', component: WheelsetCompare },
  { path: '/MapleStory/CubeSimulators/VioletCube/index.html', redirect: '/maplestory/violetCube' },
  { path: '/MapleStory/CubeSimulators/UniCube/index.html', redirect: '/maplestory/uniCube' },
  { path: '/MapleStory/CubeSimulators/EqualityCube/index.html', redirect: '/maplestory/equalityCube' },
  { path: '/PowerCompare', redirect: '/powerMeterCompare' },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;