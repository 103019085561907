<template>
  <div class="homeComponent">

    <!-- HEADER -->
    <div class="header" style = "width:100%;  display: flex; align-items: flex-start; flex-direction: column;">
      <div class="logo" style = "height:100%">MAPLESTORY</div>
      <div class="logo" style = "height:100%; font-size:12px">Nexon is laughing at you</div>
    </div>

    <!-- CONTENT -->
    <div style = "display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-items: center;
      grid-gap: 20px;
      width: 940px;
      max-width: 100%;
      margin: auto;
      margin-top: 10%;"
    >
      <!-- MapleStory -->
      <div class="items">
        <div class="item">
          <div class="front">
            <p class="word">Equality Cube</p>
          </div>
          <div class="back">
            <router-link to="/maplestory/equalityCube">
              <img src="/images/equality-cube.png">
            </router-link>
          </div>
        </div>
      </div>

      <!-- PowerCompare -->
      <div class="items">
        <div class="item">
          <div class="front">
            <p class="word">Violet Cube</p>
          </div>
          <div class="back">
            <router-link to="/maplestory/violetCube">
              <img src="/images/violet-cube.png">
            </router-link>
          </div>
        </div>
      </div>
    
      <!-- About -->
      <div class="items">
        <div class="item">
          <div class="front">
            <p class="word">UniCube</p>
          </div>
          <div class="back">
            <a href="/maplestory/uniCube">
              <img src="/images/uni-cube.png">
            </a>
          </div>
        </div>
      </div>

      <div class="items">
        <div class="item">
          <div class="front">
            <p class="word">About</p>
          </div>
          <div class="back">
            <router-link to="/maplestory/about">
              <p class="word">About</p>
            </router-link>
          </div>
      </div>
      </div>





    <!-- FOOTER -->
    <div class="footer">
      <router-link style="text-decoration: none; color: inherit;" to="/">
        <span 
          @mouseover="isHovered = true" 
          @mouseout="isHovered = false"
        >
          {{ isHovered ? 'go home' : '..' }}
        </span>
      </router-link>
    </div>

    </div>
  </div>
</template>
  
<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      isHovered: false
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&display=swap');


* {box-sizing: border-box;}

.homeComponent { 
  box-sizing: border-box;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-family: 'Oswald', sans-serif;
  background-color: rgb(0, 0, 0);

	justify-items: center;
	grid-gap: 20px;
  height: 100vh;
  margin:0;
  padding:0;
	margin: auto;
}

.items{
  perspective: 1000;
  position: relative;
  width: 100px;
  height: 100px;
  /* background: green; */
}


.item{
  position: absolute;
  color:white;
  transform-style: preserve-3d;
  transition:all 0.5s all;
  background:yellow;
  text-align: center;
  width: 100px;
  height: 100px;
  background-color: black;
}

.item:hover  {
  transform: rotateY(180deg);
  /* box-shadow: -5px 5px 5px #aaa; */
}

.front{
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background:black;
  color:#333;
}



img{
  height: 100%;
  width: 100%;
  /* margin-top: 40%; */
}

.word{
  margin-top:40%;
  color: white;
}

.back{
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background:black;
  color:white;
  transform:rotateY(180deg)
}



.header {
  overflow: hidden;
  background-color: black;
  padding: 20px 70px;
}


.header .logo {
  font-size: 25px;
  font-weight: bold;
  color: aliceblue;
}

@media screen and (max-width: 500px) {
  .header a {
    float: none;
    display: block;
    text-align: left;
  }
  
}

.footer {
  overflow: hidden;
  background-color: black;
  padding: 3px 7px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
}

#contactme{
  width: -10%;
  margin: 0 auto;
  bottom: -50%;
}
</style>
  