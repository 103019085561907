<template>
  <div class="homeComponent">

    <!-- HEADER -->
    <div class="header" style = "width:100%;  display: flex; align-items: center;">
      <div class="logo" style = "height:100%">MAIGOD.NET BY CHRIS</div>
    </div>

    <!-- CONTENT -->
    <div style = "display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-items: center;
      grid-gap: 20px;
      width: 940px;
      max-width: 100%;
      margin: auto;
      margin-top: 10%;"
    >
      <div style = "font-size: 20px; font-weight:300; color: aliceblue; display: flex; flex-direction: column; align-items: center;">
        <p>Updates:</p>
        <p> 03/11/2024 - Equality Cube Page Revamp</p>
        <p> 12/21/2024 - IED lines not showing on emblem</p>
        <p> 02/19/2024 - Remove DEF line from Unicube</p>

        <div style = "height: 40px;"></div>
        <p>About:</p>
        <p>Q: Rates Source? A: I forgot where I found the rates. </p>
        <p>Q: Why did you make this? A: I was bored. </p>
        <p>Q: Why is it so bad? A: I'm not a web developer. </p>
        <p>Q: Can I use this? A: Yes. </p>
        <p>Q: Can I use this for commercial purposes? A: No. </p>
        <p>Q: Can I use this for educational purposes? A: Yes. </p>
        <p>Q: Can I use this for personal purposes? A: Yes. </p>
        <p>Q: Can I use this for non-profit purposes? A: Yes. </p>
        

        <div style = "height: 40px;"></div>

        <p>Contact me using the following: </p>
        <div>chris@maigod.net</div>
        <div>maigod.net on discord</div>
        <div> RWOE in Bera </div>

        <!-- space -->
        <div style = "height: 40px;"></div>



        <p> I hope you're having a good time. </p>

      </div>
    </div>

    <!-- FOOTER -->
    <div class="footer">
      <router-link style="text-decoration: none; color: inherit;" to="/">
        <p>..</p>
      </router-link>
    </div>

  </div>
</template>
  
<script>
export default {
  name:'MaplestoryAbout',
  props: {
    msg: String
  },
  mounted(){
    document.title = "About";
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');


* {box-sizing: border-box;}

.homeComponent { 
  box-sizing: border-box;
  margin: 0;
  font-family: 'Oswald', sans-serif;
  background-color: rgb(0, 0, 0);

	justify-items: center;
	grid-gap: 20px;
  height: 100vh;
  margin:0;
  padding:0;
	margin: auto;
}

.items{
  perspective: 1000;
  position: relative;
  width: 100px;
  height: 100px;
  /* background: green; */
}


.item{
  position: absolute;
  color:white;
  transform-style: preserve-3d;
  transition:all 0.5s all;
  background:yellow;
  text-align: center;
  width: 100px;
  height: 100px;
  background-color: black;
}

.item:hover  {
  transform: rotateY(180deg);
  /* box-shadow: -5px 5px 5px #aaa; */
}

.front{
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background:black;
  color:#333;
}



img{
  height: 100%;
  width: 100%;
  /* margin-top: 40%; */
}

.word{
  margin-top:40%;
  color: white;
}

.back{
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background:black;
  color:white;
  transform:rotateY(180deg)
}



.header {
  overflow: hidden;
  background-color: black;
  padding: 20px 70px;
}


.header .logo {
  font-size: 25px;
  font-weight: bold;
  color: aliceblue;
}

@media screen and (max-width: 500px) {
  .header a {
    float: none;
    display: block;
    text-align: left;
  }
  
}

.footer {
  overflow: hidden;
  background-color: black;
  padding: 3px 7px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
}

#contactme{
  width: -10%;
  margin: 0 auto;
  bottom: -50%;
}
</style>
