<template>
    <div style = "height: 100vh">
        <iframe ref="iframe" src="/HTMLpages/EqualityCube/index.html" style = "width:100%; height:100vh; margin-bottom: -10px;" frameborder="0"></iframe>
        <router-link to = "/maplestory">
            <v-btn variant = "outlined" color="white" density="compact"  style = "position: absolute; bottom: 0; left: 0;">
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'VioletCube',
    mounted() {
        // // Adjust the iframe height to its content's height after it loads
        // console.log(this.$refs.iframe.contentWindow.document.body.scrollHeight)
        // this.$refs.iframe.addEventListener('load', () => {
        // this.$refs.iframe.style.height = this.$refs.iframe.contentWindow.document.body.scrollHeight + 'px';
        // this.$refs.iframe.style.width = this.$refs.iframe.contentWindow.document.body.scrollWidth*6.8 + 'px';
        // });

        document.title = "Equality Cube Simulator";
        const favicon = document.querySelector('link[rel="icon"]');
        favicon.href = "/images/equality-cube.png";
    },
}
</script>

<style scoped>
h1 {
    color: violet;
}
</style>
