<template>
    <div class="about">
        <h1>About</h1>
        <p>INTRO: This site is cookie-free and login-free, and ad-free. Which means your data will be lost upon refreshing</p>
        <p>USAGE: Click on CHOOSE FILE and select all fit files you would like to upload</p>
        <br/>

        <h1>Trouble Shooting</h1>
        <p>AVG POWER MISSING DATA: files have different duration, zoom in on the graph to where all graphs fills the x-axis</p>
        <br/>
    
        <h1>Help</h1>
        <p>This free website is a personal project. While I strive to offer great experience, replies and updates might take time </p>
        <router-link to="/contact" class="about-link">Any question please use - Contact</router-link>
        <br/>

        <v-container style = "height:4rem"/>
    
        <v-expansion-panels>
            <v-expansion-panel
                title = "Privacy Policy"
                text = "Personal Data Collection - The site does not read or collect file content, metadata, or other data from your uploaded files. 
                Use of Your Personal Data - The site does not collect any personal data. Handling of Your Files - 
                The files will exist momentarily in the server to retrieve the power information. As soon as that is finished, 
                all files will be DELETED. Cookies - The site does not collect any cookie
                Allowed Usage - This service offers functionality to analyze power data from multiple .fit files. This service is provided through web interface.
                You agree to comply with the policies and limitations concerning the use of the Mai's Power Meter Comparison Tool.
                You agree to not reverse-compile or decompile, analyze, reverse-engineer, reverse-assemble or disassemble, unlock or otherwise attempt to discover the source code
                or underlying algorithms of Mai's Power Meter Comparison Tool or attempt to do any of the foregoing in relation to the Mai's Power Meter Comparison Tool service.
                Copyright Policy - You are responsible only for the data (e.g., files, URLs) that you send to the Mai's Power Meter Comparison Tool service.
                Mai's Power Meter Comparison Tool does not monitor customer content. Please remember that illicit exchanges of recordings and protected works and hacking harm artistic creation. And please respect the laws in force, especially those concerning intellectual and artistic property."
            >
                
            
            <!-- <h1 class = "PT">Term of Service</h1>
                <h2 class = "PT">Allowed Usage</h2>
                    <h4 class = "PT">This service offers functionality to analyze power data from multiple .fit files. This service is provided through web interface. You agree to comply with the policies and limitations concerning the use of the Mai's Power Meter Comparison Tool. </h4>
                    <h4 class = "PT">You agree to not reverse-compile or decompile, analyze, reverse-engineer, reverse-assemble or disassemble, unlock or otherwise attempt to discover the source code 
                        or underlying algorithms of Mai's Power Meter Comparison Tool or attempt to do any of the foregoing in relation to the Mai's Power Meter Comparison Tool service.</h4>
                <h2 class = "PT">Copyright Policy</h2>
                    <h4 class = "PT">You are responsible only for the data (e.g., files, URLs) that you send to the Mai's Power Meter Comparison Tool service. Mai's Power Meter Comparison Tool does not monitor customer content. Please remember that illicit exchanges of recordings and protected works and hacking harm artistic creation. And please respect the laws in force, especially those concerning intellectual and artistic property.</h4>  -->
            </v-expansion-panel>
        </v-expansion-panels>
    </div>


</template>

<style scoped>
.about {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h1 {
    font-size: 24px;
    margin-bottom: 10px;
}

p {
    font-size: 16px;
    margin-bottom: 10px;
}

.about-link {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

</style>